import { styled } from "@hoken/core/styles/stitches.config";

const menuTransition = "all 400ms ease-in-out";

export const DialogBackgroundWrapper = styled("div", {
  backgroundColor: " $opacityGray",
  inset: "0",
  position: "fixed",
  transitionDuration: "150ms",
  transitionProperty: "opacity",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  zIndex: "2",
});

export const DialogWrapper = styled("div", {
  bottom: "0",
  display: "flex",
  right: "0",
  position: "fixed",
  top: "0",
  zIndex: "999999",
  width: "100%",
  justifyContent: "flex-end",
  ".navigation-panel--anchor": {
    fontSize: "$hoken-core-font-28",
    fontFamily: "$helvetica",
    letterSpacing: "-0.005em",
  },
  ".navigation-panel--wrapper": {
    width: "100%",
    height: "100%",
    transition: menuTransition,
  },
  ".navigation-panel--close": {
    width: "24px",
    height: "24px",
  },
  "@bp2": {
    ".navigation-panel--wrapper": {
      width: "50vh",
      transition: menuTransition,
    },
  },
});

export const DialogPanelOuterWrapper = styled("div", {
  backgroundColor: "$black",
  color: "$white",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: "$6",
  overflowY: "scroll",

  "@bph1": {
    overflowY: "hidden",
  },
});

export const DialogPanelInnerWrapper = styled("div", {
  display: "flex",
  paddingBottom: "$hoken-core-space-40",
  justifyContent: "space-between",
});

export const DialogFooterWrapper = styled("div", {
  textTransform: "uppercase",
  display: "grid",
  gap: "1rem",
  fontFamily: "$helvetica",
  position: "relative",
  marginTop: "2.5rem",
  li: {
    listStyle: "none",
    fontSize: "20px",
  },

  "@bph1": {
    position: "absolute",
    bottom: "2.2rem",
    marginTop: "0",
  },
});

export const DialogMenuItem = styled("li", {
  borderBottom: "1px solid $black",
  padding: "$hoken-core-space-none",
  textTransform: "uppercase",
  marginBottom: "$hoken-core-space-24",
  "&:first-child": {
    paddingTop: "$0",
  },

  "&:last-child": {
    border: "none",
  },

  p: {
    fontSize: "$medium",
    lineHeight: "$hoken-core-line-height-43",
  },
});
